import { history } from "../../store";
import notification from '../../components/notification';
import { clearCookies,getCookie,setCookies,updateCookies,removeCircleCookie,setCircleCookie} from "../../helpers/utility";
import * as service from "./service";
import {reactLocalStorage} from 'reactjs-localstorage';
import { capitalize, generateLabels, changeLanguage } from "../../helpers";
import { getCookies, getCookieByValue } from '../../helpers/utility';

// import config from "../../../config";
// import alexaLink from "./alexa-link";
export default {
  state: {
    user: getCookie() || {},
    // searchData:[],
    searchKey: '',
    notifications:[],
    invitation:false,
    loaded:false,
    deleted:false,
    invitationData:{},
    invities:{},
    failedUser:{},
    circleCount:0,
    langUsers: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError:false
      };
    },
    onSuccess(state) {
      return {
        ...state,
        loading: false
      };
    },
    onError(state,e) {
      let { data = {} } = e
      // let value = ''
      // if(data.message === config.env.forgotPassword) {
      //   value = generateLabels("SUCCESS_FORGOT_PASSWORD")
      //   notification("error", capitalize(data.message));
      // } else 
      if(e && e.status && e.status === 429) {
        notification("error", capitalize(e.data));
      }else{
        notification("error", capitalize(data.message));
      }
      return {
        ...state,
        loading: false,
        // resetAlertMsg: value,
        apiError:true
      };
    },
    onTotalUsersLangSuccess(state, data) {
      return {
        ...state,
        loading: false,
        langUsers:data
      };
    },
    onlistUsersbyYearSuccess(state,data){
      return {
        ...state,
        loading: false,
        yearUsers:data
      };
    },
    onUpdateEmailSuccess(state, res) {
      if(res) notification("success", "Email changed successfully");
      return {
        ...state,
        loading: false
      };
    },
    onHandleEmail(state, data) {
      return {
        ...state,
        loading: false,
        failedUser:data
      };
    },
    onLoginSuccess(state,data) {
      if(data && data.user && !data.user.enable_mfa) {
        setCookies(data);
      }
     if(data && data.circleData && data.circleData.rows && data.circleData.rows.length && data.circleData.rows.length === 1 && !data.user.enable_mfa){
       setCircleCookie(data.circleData.rows[0]) 
      }
      return {
        ...state,
        loading: false,
        user: data.user ? data.user : data,
        failedUser:{},
        circleCount:data && data.circleData && data.circleData.rows && data.circleData.rows.length ?  data.circleData.rows.length :0,
      };
    },
    onLinkSuccess(state,data) {
     if(data && data.circleData && data.circleData.rows && data.circleData.rows.length && data.circleData.rows.length === 1){
       setCircleCookie(data.circleData.rows[0])
      }
      return {
        ...state,
        loading: false,
        user: data.user,
        failedUser:{},
        circleCount:data && data.circleData && data.circleData.rows && data.circleData.rows.length ?  data.circleData.rows.length :0,
      };
    },

    onMagicLoginResetSuccess(state,data) {
      setCookies(data);
     if(data && data.circleData && data.circleData.rows && data.circleData.rows.length && data.circleData.rows.length === 1){
       setCircleCookie(data.circleData.rows[0]) 
      }
      history.push('/dashboard')
      window.location.reload();
      return {
        ...state,
        loading: false,
        user: data.user,
        failedUser:{},
        circleCount:data && data.circleData && data.circleData.rows && data.circleData.rows.length ?  data.circleData.rows.length :0,
      };
    },

    onMagicLoginSuccess(state,data) {
      setCookies(data);
     if(data && data.circleData && data.circleData.rows && data.circleData.rows.length && data.circleData.rows.length === 1){
       setCircleCookie(data.circleData.rows[0]) 
      }
      window.location.reload();
      return {
        ...state,
        loading: false,
        user: data.user,
        failedUser:{},
        circleCount:data && data.circleData && data.circleData.rows && data.circleData.rows.length ?  data.circleData.rows.length :0,
      };
    },
    onRegisterSuccess(state,data) {
      setCookies(data);
      return {
        ...state,
        loading: false,
        failedUser:{},
        user: data.user
      };
    },
    onLogoutSuccess(state) {
      setTimeout(function(){ 
        //history.push("/");
        return {}
      }, 3000);
      // history.push("/");
      // return {
        
      // };
    },
    onUpdateProfileSuccess(state, data) {
      let user = getCookies();
      user = JSON.parse(user)
      if(user.email !== (data.user && data.user.email)) {
        notification("success", generateLabels("MY_ACCOUNT_SUCCESS_MESSAGE2"));
      } else notification("success", generateLabels("MY_ACCOUNT_SUCCESS_MESSAGE"));

      updateCookies(data.user)
      return {
        ...state,
        loading: false,
        user: data.user
      };
    },
    onUpdateProfileLangSuccess(state, data) {
      // let user = getCookies();
      // user = JSON.parse(user)
      updateCookies(data.user)
      return {
        ...state,
        loading: false,
        user: data.user
      };
    },
    onListUsersSuccess(state, data) {
      return {
        ...state,
        loading: false,
        allUsers: data
      };
    },
    onGetProfileSuccess(state, data) {
      updateCookies(data.user)
      return {
        ...state,
        loading: false,
        user: data.user
      };
    },
    onForgetPwdSuccess(state, { data }) {
      //notification("success", 'Password rest link send to your mailid');
      return {
        ...state,
        loading: false,
        failedUser:{},
        resetAlertMsg: generateLabels("SUCCESS_FORGOT_PASSWORD")
      };
    },
    clearResetAlertMsgSuccess(state) {
      return {
        ...state,
        loading: false,
        resetAlertMsg: null
      }
    },
    onResetPasswordSuccess(state, { data }) {
      notification("success", generateLabels("TOASTERS_PASSWORD"));
      history.push("/login");
      return {
        ...state,
        loading: false,
        failedUser:{},
      };
    },
    onChangePasswordSuccess(state, { data }) {
      notification("success", generateLabels("MY_ACCOUNT_CHANGE_PW_SUCCESS"));
      history.push("/dashboard");
      return {
        ...state,
        loading: false,
      };
    },
    onSearchSuccess(state,data ) {
      let val = []
      if(data.res && data.res.hits && data.res.hits.hits) {
        for(var i=0; i<data.res.hits.hits.length; i++) {
          val.push(data.res.hits.hits[i]._source)
        }
      }
      return {
        ...state,
        loading: false,
        searchData:val,
        searchCount:data.res && data.res.hits && data.res.hits.total,
        // searchData:data.res,
        searchKey: data.payload.search_by,
        searchTag: data.payload.module
      };
    },
    onSearchLoadMoreSuccess(state, data) {
      return {
        ...state,
        loading: false,
        searchData: [...state.searchData, ...data]
      }
    },
    onGetNotificationSuccess(state,data ) {
      return {
        ...state,
        loading: false,
        notifications:data
      };
    },
    onGetNotificationCountSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        notifications: data
      }
    },
    onReadNotificationSuccess(state,data ) {
      return {
        ...state,
        loading: false,
      };
    },
    onAcceptInvitationSuccess(state,data){
      notification("success", capitalize(generateLabels("TOASTERS_INVI_ACCEPT")));
      return {
        ...state,
        loading: false,
        invitation:false,
        invitationData:{}
      };
    },
    onRejectInvitationSuccess(state,data){
      notification("success", capitalize(generateLabels("TOASTERS_INVI_REJECT")));
      return {
        ...state,
        loading: false,
      };
    },
    onMeSuccess(state,data){
      return {
        ...state,
        loading: false,
        loaded:true,
        me: data,
        userData: data
      };
    },
    onCirclePermissions(state,data){
      return {
        ...state,
        loading: false,
      };
    },
    onStoreInvitationSuccess(state,data){
      return {
        ...state,
        loading: false,
        invitation:true,
        invitationData:data
      };
    },
    onGetInvitiesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        invities: data
      };
    },
    onResendInvitiesSuccess(state, data) {
      notification("success", 'Sucessfully send a reminder');
      return {
        ...state,
        loading: false,
      };
    },
    onDeleteInvitiesSuccess(state, data) {
      notification("success", generateLabels("TOASTERS_INVI_DELETE"));
      return {
        ...state,
        loading: false,
      };
    },
    onDeleteAllInvitiesSuccess(state, data) {
      // notification("success", "Invitation has been deleted");
      return {
        ...state,
        loading: false,
      };
    },
    onExportDataSuccess(state, data) {
      notification('success', capitalize(data.message))
      return {
        ...state,
        loading: false,
      }
    },
    onDeleteAccountSuccess(state, data) {
      notification('success', generateLabels("TOASTERS_ACC_DELETE"))
      clearCookies();
      removeCircleCookie();
      localStorage.removeItem('acl');
		  // localStorage.removeItem('sortList');
      return {
        ...state,
        loading: false,
        deleted:true
      }
    },
    onGetUserByIdSuccess(state, data) {
      return {
        ...state,
        loading: false,
        userById: data
      }
    },
    onGetSettingsSuccess(state, data) {
      return {
        ...state, 
        loading: false
      }
    },
    onForgotPasswordSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        userEmail: data
      }
    },
    onResendMagicURLSuccess(state, data) {
      return {
        ...state, 
        loading: false,
        magicURL: data
      }
    },
    onUserListSuccess(state,data){
      return{
        ...state,
        loading:false,
        userListData:data
      }
    },
    onUserListClear(state,data){
      return{
        ...state,
        loading:false,
        userListData:null
      }
    },
    onmfaSuccess(state,data) {
      setCookies(data);
     if(data && data.circleData && data.circleData.rows && data.circleData.rows.length && data.circleData.rows.length === 1){
       setCircleCookie(data.circleData.rows[0]) 
      }
      if(data && data.user && data.user.cuk_circle && data.user.cuk_circle.circle_slug) {
        history.push(`/circle/${data.user.cuk_circle.circle_slug}/overview`)
        // window.location.reload();
      }
      else {
        history.push('/dashboard')
        // window.location.reload();
      }
      return {
        ...state,
        loading: false,
        user: data.user,
        failedUser:{},
        circleCount:data && data.circleData && data.circleData.rows && data.circleData.rows.length ?  data.circleData.rows.length :0,
      };
    },
    onresendOtpSuccess(state,data){
      return{
        ...state,
        loading:false,
        user:data
      }
    },
    onenableMfaSuccess(state,data){
      return{
        ...state,
        loading:false,
        user:data
      }
    },
    
  },
  effects: {
    async login(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.login(payload);
        this.onLoginSuccess(res);
        return res;
      } catch (e) {
        this.onHandleEmail(payload)
        this.onError(e);
      }
    },
    async alexalink(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.alexalink(payload);
        this.onLinkSuccess(res);
        return res;
      } catch (e) {
        this.onHandleEmail(payload)
        this.onError(e);
      }
    },
    async register(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.signup(payload);
        this.onRegisterSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async logout(payload, rootState) {
      try {
        await service.logout(payload)
        await localStorage.removeItem('acl');
        await clearCookies()
        await removeCircleCookie();
        // await localStorage.removeItem('sortList');
        window.location.reload();
      } catch (e) {
        this.onError(e);
      }
    },
    async getProfile(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getProfile(payload);
        this.onGetProfileSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async me(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.me(payload);
        if(res.user.language !== getCookieByValue("lang")) {
          await changeLanguage(res.user.language)
          window.location.reload()
        }
        reactLocalStorage.setObject('acl', res.permissions);
        reactLocalStorage.setObject('sortList', res.user.sort_order);
        this.onMeSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getCirclePermissions(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getCirclePermissions(payload);
        reactLocalStorage.setObject('acl', res);
        this.onCirclePermissions(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateProfile(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.updateProfile(payload);
         this.onUpdateProfileSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async updateProfileLang(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.updateProfile(payload);
         this.onUpdateProfileLangSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async forgotPassword(payload, rootState) {
      this.onRequest();
      try {
        let res =  await service.forgotPassword(payload)
        this.onForgetPwdSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    clearResetAlertMsg(rootState) {
      this.clearResetAlertMsgSuccess()
    },
    async resetPassword(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resetPassword(payload)
        this.onResetPasswordSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async changePassword(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.changePassword(payload)
        this.onChangePasswordSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async magicLogin(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.magicLogin(payload)
        if(payload.reset) this.onMagicLoginResetSuccess(res);
        else this.onMagicLoginSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async resendMagicLogin(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resendMagicLogin(payload)
        this.onResendMagicURLSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async search(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.search(payload)
        this.onSearchSuccess({res, payload});
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async searchLoadMore(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.search(payload)
        this.onSearchLoadMoreSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getNotification(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getNotification(payload)
        this.onGetNotificationSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async getNotificationCount(payload, rootState) {
      this.onRequest()
      try {
        let res =  await service.getNotificationCount(payload)
        this.onGetNotificationCountSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async readNotification(payload, rootState) {
    //  this.onRequest();
      try {
        let res = await service.readNotification(payload)
        this.onReadNotificationSuccess(res);
        this.getNotification()
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async readAllNotification(payload, rootState) {
     // this.onRequest();
      try {
        let res = await service.readAllNotification(payload)
        this.onReadNotificationSuccess(res);
        this.getNotification()
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteAllNotification(payload, rootState) {
      // this.onRequest();
       try {
         let res = await service.deleteAllNotification(payload)
         this.onReadNotificationSuccess(res);
         this.getNotification()
         return res;
       } catch (e) {
         this.onError(e);
       }
     },
    async deleteNotification(payload, rootState) {
      //  this.onRequest();
        try {
          let res = await service.deleteNotification(payload)
          this.onReadNotificationSuccess(res);
          this.getNotification()
          return res;
        } catch (e) {
          this.onError(e);
        }
      },
    async acceptInvitation(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.acceptInvitation(payload)
        this.onAcceptInvitationSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async createInvitationNote(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createInvitationNote(payload)
        // this.onAcceptInvitationSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async rejectInvitation(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.rejectInvitation(payload)
        this.onRejectInvitationSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async storeInvitation(payload, rootState) {
      try {
        this.onStoreInvitationSuccess(payload);
      } catch (e) {

      }
    },
    async getInvities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getInvities(payload)
        this.onGetInvitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async resendInvities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resendInvities(payload)
        this.onResendInvitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteInvities(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteInvities(payload)
        this.onDeleteInvitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async deleteAllinvitee(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteAllinvitee(payload)
        this.onDeleteAllInvitiesSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    
    async exportAll(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.exportAll(payload)
        this.onExportDataSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async deleteAccount(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteAccount(payload)
        this.onDeleteAccountSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getUserById(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getUserById(payload)
        this.onGetUserByIdSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async getSettings(payload) {
      this.onRequest()
      try{
        let res = await service.getSettings()
        await this.onGetSettingsSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async forgotPasswordEmail(payload) {
      this.onRequest()
      try{
        await this.onForgotPasswordSuccess(payload)
        return payload
      }
      catch(e) {
        this.onError(e)
      }
    },
    async listUsers(payload) {
      this.onRequest()
      try{
        let res = await service.listUsers(payload)
        await this.onListUsersSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async passwordResetLink(payload) {
      this.onRequest()
      try{
        let res = await service.passwordResetLink(payload)
        await this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async checkPassword(payload) {
      this.onRequest()
      try{
        let res = await service.checkPassword(payload)
        await this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async totalUsersLang(payload) {
      this.onRequest()
      try{
        let res = await service.totalUsersLang(payload)
        if(payload && payload.year){
          await this.onlistUsersbyYearSuccess(res)
        }else{
          await this.onTotalUsersLangSuccess(res)
        }
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async circleByUserId(payload) {
      this.onRequest()
      try{
        let res = await service.circleByUserId(payload)
        await this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async updateUserEmail(payload) {
      this.onRequest()
      try{
        let res = await service.updateUserEmail(payload)
        await this.onUpdateEmailSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async whatsNew(payload) {
      this.onRequest()
      try{
        let res = await service.whatsNew(payload)
        await this.onSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    async usersListAnalytics(payload){
      this.onRequest()
      try{
        let res = await service.usersListAnalytics(payload)
        await this.onUserListSuccess(res)
        return res
      }
      catch(e) {
        this.onError(e)
      }
    },
    clearUsersList() {
      try {
        this.onUserListClear()
      }
      catch (e) {
        this.onError(e)
      }
    },
    async exportUserAnalytics(payload){
      this.onRequest()
      try{
        let res = await service.exportUserAnalytics(payload)
        if(res && res.message){
          notification("success",res.message)
        }
        return res
      }catch(e){
        this.onError(e)
      }
    },
    async multiFactorAuth(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.multiFactorAuth(payload);
        this.onmfaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async resendOtp(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.resendOtp(payload);
        this.onresendOtpSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async enableMfa(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.enableMfa(payload);
        this.onenableMfaSuccess(res);
        return res;
      } catch (e) {
        this.onError(e);
      }
    },


  }
};
